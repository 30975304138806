require('./components/alternative-colors')
require("./components/cart")
require("./components/catalog-filter")
require("./components/favorites")
require("./components/import-items")
require("./components/informer")
require("./components/loupe")
require("./components/order-state")
require("./components/other-item-links")
require("./components/payment-button")
require("./components/scrolling-header")
require("./components/view-history")

import Carousel from "@biovision/biovision/components/carousel";
import Storage from "@biovision/biovision/components/storage";

import AlternativeColors from "./components/alternative-colors";
import Cart from "./components/cart";
import CatalogFilter from "./components/catalog-filter";
import Favorites from "./components/favorites";
import ImportItems from "./components/import-items";
import Informer from "./components/informer";
import Loupe from "./components/loupe";
import OrderState from "./components/order-state";
import OtherItemLinks from "./components/other-item-links";
import PaymentButton from "./components/payment-button";
import ScrollingHeader from "./components/scrolling-header";
import ViewHistory from "./components/view-history";

Biovision.addComponent(Storage);
Biovision.addComponent(Carousel);

Biovision.addComponent(AlternativeColors);
Biovision.addComponent(Cart);
Biovision.addComponent(CatalogFilter);
Biovision.addComponent(Favorites);
Biovision.addComponent(ImportItems);
Biovision.addComponent(Informer);
Biovision.addComponent(Loupe);
Biovision.addComponent(OrderState);
Biovision.addComponent(OtherItemLinks);
Biovision.addComponent(PaymentButton);
Biovision.addComponent(ScrollingHeader);
Biovision.addComponent(ViewHistory);

const ViewHistory = {
    id: "viewHistory",
    initialized: false,
    itemSelector: ".shop__item",
    itemIds: [],
    current: undefined,
    containerSelector: ".js-item-history-container",
    container: undefined,
    storageKey: "visited-items",
    init: function () {
        this.current = document.querySelector(this.itemSelector);
        this.container = document.querySelector(this.containerSelector);
        if (this.container) {
            this.load();
        }
        if (this.current) {
            this.add(this.current.dataset.id);
        }
        this.initialized = true;
    },
    add: function (id) {
        const visited = Biovision.storage.local.get(this.storageKey) + "";
        this.items = visited.split(",").map(i => parseInt(i)).filter(i => i > 0);
        this.items.unshift(parseInt(id));

        try {
            const items = this.items;
            const newIds = Array.from(new Set(items)).slice(0, 12);

            Biovision.storage.local.set(this.storageKey, newIds.join(","));
        } catch (e) {
            console.log(e);
        }
    },
    load: function () {
        const container = this.container;
        const url = container.dataset.url;
        if (url) {
            const ids = Biovision.storage.local.get(this.storageKey) + "";
            const request = Biovision.newAjaxRequest("get", url + "?ids=" + ids, ViewHistory.parseResponse);
            request.send();
        } else {
            console.log("No URL for getting history");
        }
    },
    parseResponse: function () {
        const response = JSON.parse(this.responseText);
        let buffer = "";
        if (response.hasOwnProperty("data")) {
            response.data.forEach(function (item) {
                buffer += item["meta"]["html"];
            });
        }

        ViewHistory.container.innerHTML = buffer;
    }
};

export default ViewHistory;

/**
 * Toggle item as favorite
 *
 * @type {Object}
 */
const Favorites = {
    id: "favorites",
    /**
     * Component is initialized
     *
     * @type {Boolean}
     */
    initialized: false,
    /**
     * CSS selector for buttons
     *
     * @type {String}
     */
    selector: ".js-favorite__toggle",
    /**
     * Key for local storage of favorite item ids
     *
     * @type {String}
     */
    key: "favorite-items",
    /**
     * Buttons for toggling item state
     *
     * @type {Array<HTMLElement>}
     */
    elements: [],
    /**
     * Favorite item ids
     *
     * @type {Array<Number>}
     */
    itemIds: [],
    counter: undefined,
    container: undefined,
    init: function () {
        this.counter = document.getElementById("header-favorites");
        this.container = document.querySelector(".js-favorites-container");

        const itemIds = String(Biovision.storage.local.get(this.key));

        this.itemIds = itemIds.split(",").map(i => parseInt(i)).filter(i => i > 0);
        document.querySelectorAll(this.selector).forEach(this.apply);

        if (this.itemIds.length > 0) {
            if (this.counter) {
                this.counter.dataset.count = String(this.itemIds.length);
            }

            if (this.container) {
                this.load();
            }
        }

        this.initialized = true;
    },
    apply: function (element) {
        Favorites.elements.push(element);
        element.addEventListener("click", Favorites.handler);

        if (Favorites.itemIds.indexOf(parseInt(element.value)) >= 0) {
            element.checked = true;
        }
    },
    handler: function (event) {
        const element = event.target;
        const itemId = parseInt(element.value);
        if (element.checked) {
            Favorites.add(itemId);
        } else {
            Favorites.remove(itemId);
        }
    },
    add: function (itemId) {
        this.itemIds.unshift(parseInt(itemId));

        try {
            this.itemIds = Array.from(new Set(this.itemIds));
        } catch (e) {
            console.log(e);
        }
        Biovision.storage.local.set(this.key, this.itemIds.join(","));
        this.refreshCounter();
    },
    remove: function (itemId) {
        this.itemIds = this.itemIds.filter(element => element !== itemId);
        Biovision.storage.local.set(this.key, this.itemIds.join(","));
        this.refreshCounter();
    },
    refreshCounter: function () {
        if (this.counter) {
            if (this.itemIds.length > 0) {
                this.counter.dataset.count = String(this.itemIds.length)
            } else {
                this.counter.removeAttribute("data-count")
            }
        }
    },
    load: function () {
        const container = this.container;
        const url = container.dataset.url;
        if (url) {
            const ids = this.itemIds.join(",");
            const request = Biovision.newAjaxRequest("get", url + "?ids=" + ids, function () {
                const response = JSON.parse(this.responseText);
                let buffer = '';
                if (response.hasOwnProperty("data")) {
                    response.data.forEach(function (item) {
                        buffer += item["meta"]["html"];
                    });
                }

                container.innerHTML = buffer;
                container.querySelectorAll(Favorites.selector).forEach(Favorites.apply);
            });
            request.send();
        } else {
            console.log("No URL for getting favorites");
        }
    }
};

export default Favorites;

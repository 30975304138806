const Informer = {
    id: "informer",
    container: undefined,
    init: function () {
        this.container = document.createElement("ul");
        this.container.classList.add("biovision-informer");
        document.body.append(this.container);
    },
    inform: function (text, className = "info") {
        const element = document.createElement("li");
        element.classList.add(className);
        element.innerHTML = text;
        element.addEventListener("click", (e) => Informer.dismiss(e.target));
        window.setTimeout(() => Informer.dismiss(element), 3000);
        this.container.append(element);
    },
    dismiss: function (element) {
        if (!element.classList.contains("dismissed")) {
            element.classList.add("dismissed");
            window.setTimeout(() => element.remove(), 750);
        }
    },
    info: function (text) {
        this.inform(text, "info");
    },
    success: function (text) {
        this.inform(text, "success");
    },
    warning: function (text) {
        this.inform(text, "warning");
    },
    error: function (text) {
        this.inform(text, "error");
    }
}

export default Informer;

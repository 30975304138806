const AlternativeColors = {
    id: 'alternativeColors',
    selector: ".item-alternative-colors",
    addButton: undefined,
    removeButtons: [],
    newArticles: undefined,
    items: new Map(),
    list: undefined,
    init: function () {
        this.list = document.querySelector(this.selector);
        if (this.list) {
            this.addButton = this.list.querySelector(".add-item-alternative");
            this.newArticles = this.list.querySelector(".add-alternative input");
            if (this.addButton) {
                this.addButton.addEventListener("click", this.addAlternatives);
            }
            this.list.querySelectorAll("li[data-item-id]").forEach(this.addItem);
            this.list.querySelectorAll(".remove-alternative-color").forEach(this.addRemovalButton);
        }
    },
    addItem: function (element) {
        const component = AlternativeColors;
        const itemId = parseInt(element.dataset.itemId);
        component.items.set(itemId, element);
    },
    addAlternatives: function () {
        const component = AlternativeColors;
        if (component.newArticles.value !== "") {
            const url = component.addButton.dataset.url;
            const request = Biovision.jsonAjaxRequest("post", url, component.processAddResponse);
            const data = {"item_articles": component.newArticles.value}
            component.addButton.disabled = true;
            request.send(JSON.stringify(data));
        }
    },
    addRemovalButton: function (element) {
        const component = AlternativeColors;
        component.removeButtons.push(element);
        element.addEventListener("click", component.handleRemove);
    },
    processAddResponse: function () {
        const component = AlternativeColors;
        const response = JSON.parse(this.responseText);
        console.log(response);
        if (response.hasOwnProperty("data")) {
            response.data.forEach(component.processAddedItem);
        }
        component.newArticles.value = "";
        component.addButton.disabled = false;
    },
    processAddedItem: function (data) {
        const component = AlternativeColors;
        const itemId = data.id;
        if (!component.items.has(itemId)) {
            const li = document.createElement("li");
            li.dataset.itemId = itemId;
            if (data.hasOwnProperty("meta") && data.hasOwnProperty("links")) {
                const anchor = document.createElement("a");
                anchor.href = data.links.self
                anchor.innerHTML = data.attributes.article;
                const button = document.createElement("button");
                button.classList.add("remove-alternative-color");
                button.dataset.url = data.links["remove_alternative"];
                const text = document.createTextNode(": " + data.meta["color_name"]);
                component.addRemovalButton(button);
                li.append(button);
                li.append(anchor);
                li.append(text);
                component.items.set(itemId, li);
                component.list.prepend(li);
            }
        }
    },
    handleRemove: function (event) {
        const component = AlternativeColors;
        const button = event.target;
        const url = button.dataset.url;
        const request = Biovision.jsonAjaxRequest("delete", url, function () {
            const itemId = button.closest("li").dataset.itemId;
            if (component.items.has(itemId)) {
                component.items.delete(itemId);
            }
            button.closest("li").remove();
        });
        request.send();
    }
}

export default AlternativeColors;

const OrderState = {
    id: "orderState",
    selector: ".js-order-state",
    container: undefined,
    url: undefined,
    span: undefined,
    init: function () {
        this.container = document.querySelector(this.selector);
        if (this.container) {
            const handler = this.refresh;
            this.url = this.container.dataset.url;
            this.span = this.container.querySelector("span");
            this.span.addEventListener("click", handler);
            this.refresh();
        }
    },
    refresh: function () {
        const request = Biovision.jsonAjaxRequest("get", OrderState.url, function () {
            const response = JSON.parse(this.responseText);
            if (response.hasOwnProperty("meta")) {
                if (response.meta.state > 0) {
                    OrderState.span.innerHTML = response.meta.message;
                } else {
                    setTimeout(OrderState.refresh, 1000);
                }
            }
        });

        OrderState.span.innerHTML = OrderState.span.dataset.text;
        request.send();
    }
}

export default OrderState;

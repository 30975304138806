const OtherItemLinks = {
    selector: ".js-other-item-links",
    id: 'otherItemLinks',
    container: undefined,
    url: undefined,
    button: undefined,
    input: undefined,
    list: undefined,
    init: function () {
        this.container = document.querySelector(this.selector);
        if (this.container) {
            const component = this;
            this.url = this.container.dataset.url;
            this.input = this.container.querySelector("input");
            this.button = this.container.querySelector("button");
            this.list = this.container.querySelector(".entity-links");
            this.button.addEventListener("click", component.handleButtonClick);
        }
    },
    handleButtonClick: function () {
        const component = OtherItemLinks;
        if (component.input.value !== "") {
            const url = `${component.url}?q=${encodeURIComponent(component.input.value)}`
            const request = Biovision.jsonAjaxRequest("get", url, component.parseResponse);
            component.button.disabled = true;
            request.send();
        }
    },
    parseResponse: function () {
        const component = OtherItemLinks;
        const response = JSON.parse(this.responseText);
        component.button.disabled = false;
        if (response.hasOwnProperty("data")) {
            response.data.forEach(component.addItemToList);
        }
    },
    addItemToList: function (data) {
        const component = OtherItemLinks;
        const itemId = data.id;
        if (!component.list.querySelector(`li[data-id="${itemId}"]`)) {
            const elementId = `other-item-${itemId}`;
            const li = document.createElement("li");
            li.dataset.id = itemId;

            const checkbox = document.createElement("input");
            checkbox.type = "checkbox";
            checkbox.dataset.url = data.links.pair;
            checkbox.id = elementId;
            checkbox.checked = data.meta.link_exists;
            li.append(checkbox);

            const label = document.createElement("label");
            label.htmlFor = elementId;
            label.innerHTML = `${data.attributes.article} (${data.attributes.name})`
            li.append(label);
            component.list.append(li);
            Biovision.components.entityLinker.apply(checkbox);
        }
    }
}

export default OtherItemLinks;

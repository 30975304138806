const ScrollingHeader = {
    id: "scrollingHeader",
    header: undefined,
    init: function () {
        this.header = document.getElementById("main-header");
        if (this.header) {
            this.checkScroll();
            window.addEventListener("scroll", this.scroll);
        }
    },
    checkScroll: function () {
        if (window.pageYOffset >= 69) {
            this.header.classList.add("scrolled");
        }
    },
    scroll: function () {
        if (ScrollingHeader.header) {
            if (window.pageYOffset > 10) {
                ScrollingHeader.header.classList.add("scrolled");
            } else {
                ScrollingHeader.header.classList.remove("scrolled");
            }
        }
    }
}

export default ScrollingHeader;

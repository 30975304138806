const ImportItems = {
    id: 'importItems',
    selector: ".js-import-items",
    form: undefined,
    init: function () {
        this.form = document.querySelector(this.selector);
        if (this.form) {
            const handler = this.process;
            this.form.addEventListener("submit", handler);
        }
    },
    process: function (event) {
        event.preventDefault();
        const form = event.target;
        const button = form.querySelector("button");
        const request = Biovision.newAjaxRequest("post", form.action, function () {
            form.reset();
            button.disabled = false;
            button.innerHTML = "Готово"
        });

        button.disabled = true;
        const formData = new FormData();
        formData.set("file", form.querySelector("input").files[0]);
        request.send(formData);
    }
}

export default ImportItems;

const Cart = {
    id: "cart",
    headerCartId: "header-cart",
    headerCart: undefined,
    url: undefined,
    cartButtonSelector: ".js-item-cart-button",
    cartButtons: [],
    finalPriceId: "shop__order-price",
    finalPrice: undefined,
    quantitySelectorSelector: ".quantity-selector",
    quantitySelectors: [],
    quantityInputSelector: "input.order-quantity",
    quantityInputs: [],
    removeButtonSelector: ".js-cart__remove-item",
    removeButtons: [],
    promoCode: {
        selector: ".js-order-promo-code",
        container: undefined,
        button: undefined,
        input: undefined,
        status: undefined,
    },
    deliveryOptions: {
        selector: "order_delivery",
        table: undefined,
        switcher: undefined,
        releaser: undefined,
        options: [],
        row: undefined,
        total: undefined,
        fields: [],
    },
    init: function () {
        this.headerCart = document.getElementById(this.headerCartId);
        if (this.headerCart) {
            this.url = this.headerCart.dataset.url;
        }

        this.finalPrice = document.getElementById(this.finalPriceId);

        document.querySelectorAll(this.cartButtonSelector).forEach(this.applyCartButton);
        document.querySelectorAll(`${this.quantitySelectorSelector} button`).forEach(this.applyQuantitySelector);
        document.querySelectorAll(this.quantityInputSelector).forEach(this.applyQuantityInput);
        document.querySelectorAll(this.removeButtonSelector).forEach(this.applyRemoveButton);
        this.promoCode.container = document.querySelector(this.promoCode.selector);
        if (this.promoCode.container) {
            const container = this.promoCode.container;
            this.promoCode.input = container.querySelector("input");
            this.promoCode.button = container.querySelector("button");
            this.promoCode.status = container.querySelector(".status");
            this.promoCode.button.addEventListener("click", Cart.promoCodeButtonClickHandler);
        }

        this.deliveryOptions.switcher = document.getElementById(this.deliveryOptions.selector + "_1");
        this.deliveryOptions.releaser = document.getElementById(this.deliveryOptions.selector + "_0")
        this.deliveryOptions.table = document.querySelector(".shop__cart-items");
        this.deliveryOptions.total = document.getElementById("amount_price");
        if (this.deliveryOptions.switcher && this.deliveryOptions.releaser && this.deliveryOptions.table) {
            const component = this.deliveryOptions
            component.row = component.table.querySelector("tr.delivery");
            component.switcher.addEventListener("change", this.engageDelivery);
            component.releaser.addEventListener("change", this.releaseDelivery);
            component.fields = document.querySelectorAll(".cart-delivery-options .required");
            document.querySelectorAll(".cart-delivery-options .options input").forEach(this.addDeliveryOption);
            this.recalculateDelivery();
        }

        this.refresh();
    },
    refresh: function () {
        if (this.headerCart && this.url) {
            Biovision.jsonAjaxRequest("get", this.url, Cart.parseResponse).send();
        }
    },
    setItemCount: function (newValue) {
        if (this.headerCart) {
            if (newValue > 0) {
                this.headerCart.dataset.count = String(newValue);
            } else {
                this.headerCart.removeAttribute("data-count");
            }
        }
    },
    parseResponse: function () {
        const response = JSON.parse(this.responseText);

        if (response.hasOwnProperty("meta")) {
            Cart.parseCartMeta(response.meta);
        }
    },
    applyCartButton: function (element) {
        Cart.cartButtons.push(element);
        element.addEventListener("click", Cart.cartButtonClickHandler);
    },
    cartButtonClickHandler: function (event) {
        const button = event.target;
        const url = button.dataset.url;
        Biovision.jsonAjaxRequest("post", url, Cart.parseResponse).send();
    },
    pushItem: function (element) {
        Cart.items.push(element);
    },
    parseCartMeta: function (meta) {
        if (meta.hasOwnProperty("item_count")) {
            Cart.setItemCount(meta.item_count);
        }
        if (meta.hasOwnProperty("message")) {
            Biovision.components.informer.success(meta.message);
        }
        if (meta.hasOwnProperty("price") && Cart.finalPrice) {
            Cart.finalPrice.innerHTML = meta["price"];
        }
    },
    applyQuantitySelector: function (element) {
        Cart.quantitySelectors.push(element);
        element.addEventListener("click", Cart.quantityButtonClickHandler);
    },
    quantityButtonClickHandler: function (event) {
        const button = event.target;
        const container = button.closest(Cart.quantitySelectorSelector);
        const input = container.querySelector("input");
        const oldValue = parseInt(input.value);
        let change = true;
        if (button.classList.contains("decrement")) {
            if (oldValue > 1) {
                input.value = String(oldValue - 1);
            }
        } else {
            input.value = String(oldValue + 1);
        }

        if (change) {
            const inputEvent = new Event("input", {bubbles: true, cancelable: true});
            input.dispatchEvent(inputEvent);
        }
    },
    applyQuantityInput: function (element) {
        Cart.quantityInputs.push(element);
        element.addEventListener("input", Cart.quantityInputInputHandler);
    },
    quantityInputInputHandler: function (event) {
        const element = event.target;
        const price = parseInt(element.dataset.price);
        const container = element.closest(".cart-item")
        const url = container.getAttribute("data-url");
        element.value = element.value.replace(new RegExp(/\D/, "g"), "");
        if (element.value === "") {
            element.value = 1;
        }
        if (parseInt(element.value) > parseInt(element.max)) {
            element.value = element.max;
        }
        const data = JSON.stringify({"quantity": element.value});
        Biovision.jsonAjaxRequest('put', url, Cart.parseResponse).send(data);
        if (price > 0) {
            const newPrice = price * parseInt(element.value);
            if (newPrice > 0) {
                container.querySelector(".total-price").innerHTML = String(newPrice);
            } else {
                container.remove();
            }
        } else {
            if (element.value < 1) {
                container.remove();
            }
        }
    },
    applyRemoveButton: function (element) {
        Cart.removeButtons.push(element);
        element.addEventListener("click", Cart.removeButtonClickHandler);
    },
    removeButtonClickHandler: function (event) {
        const container = event.target.closest(".cart-item");
        const url = container.dataset.url;
        const data = JSON.stringify({"quantity": 0});
        Biovision.jsonAjaxRequest("put", url, Cart.parseResponse).send(data);
        container.remove();
    },
    promoCodeButtonClickHandler: function () {
        const component = Cart.promoCode;
        if (component.input.value !== "") {
            const url = component.button.dataset.url;
            const request = Biovision.jsonAjaxRequest("post", url, Cart.promoCodeHandleResponse);
            const data = {code: component.input.value};
            component.status.innerHTML = "";
            request.send(JSON.stringify(data));
        }
    },
    promoCodeHandleResponse: function () {
        const component = Cart.promoCode;
        const response = JSON.parse(this.responseText);
        if (response.hasOwnProperty("meta")) {
            const meta = response.meta;
            component.status.innerHTML = meta.message;
            const container = component.container.closest("tr").querySelector(".js-cart-total");
            let price = meta.price;
            if (container.hasAttribute("data-delivery")) {
                price += parseInt(container.dataset.delivery);
            }
            if (container) {
                container.innerHTML = price;
                container.dataset.price = price;
            }
        }
    },
    engageDelivery: function (event) {
        const component = Cart.deliveryOptions;
        const radio = event.target;
        if (radio.checked) {
            component.row.classList.remove("hidden");
            component.fields.forEach(function(field) {
                field.required = true;
            });
        }
        Cart.recalculateDelivery();
    },
    releaseDelivery: function (event) {
        const component = Cart.deliveryOptions;
        const radio = event.target;
        const text = component.row.querySelector(".text");
        if (radio.checked) {
            component.row.classList.remove("hidden");
            text.innerHTML = "Самовывоз";
            text.classList.add("highlight");
            component.row.querySelector(".value").innerHTML = "0";
            component.total.innerHTML = component.total.dataset.value;
            component.total.dataset.delivery = "0";
            component.fields.forEach(function(field) {
                field.removeAttribute("required");
            });

            if (Cart.promoCode.button) {
                Cart.promoCode.button.click();
            }
        }
    },
    addDeliveryOption: function (element) {
        Cart.deliveryOptions.options.push(element);
        element.addEventListener("change", Cart.recalculateDelivery);
    },
    recalculateDelivery: function () {
        const component = Cart.deliveryOptions;
        const text = component.row.querySelector(".text");
        component.options.forEach(function (radio) {
            if (radio.checked) {
                component.row.classList.remove("hidden");
                text.innerHTML = radio.dataset.text;
                const deliveryPrice = component.row.querySelector(".value");
                if (radio.hasAttribute("data-rate")) {
                    const initialPrice = parseInt(component.total.dataset.value);
                    const rate = parseFloat(radio.dataset.rate);
                    const newPrice = parseInt(Math.ceil(initialPrice * rate));
                    deliveryPrice.innerHTML = String(newPrice - initialPrice) + " руб.";
                    component.total.innerHTML = String(newPrice);
                    component.total.dataset.delivery = String(newPrice - initialPrice);
                } else {
                    component.total.innerHTML = component.total.dataset.value;
                    component.total.dataset.delivery = "0";
                    deliveryPrice.innerHTML = "";
                }
                if (radio.hasAttribute("data-highlight")) {
                    text.classList.add("highlight");
                } else {
                    text.classList.remove("highlight");
                }
            }
        });

        if (component.releaser.checked) {
            text.innerHTML = "Самовывоз";
            text.classList.add("highlight");
            component.row.querySelector(".value").innerHTML = "0";
            component.row.classList.remove("hidden");
            component.total.dataset.delivery = "0";
        }

        if (Cart.promoCode.button) {
            Cart.promoCode.button.click();
        }
    }
}

export default Cart;

const CatalogFilter = {
    id: "catalogFilter",
    filterSelector: ".js-catalog-filter",
    filterContainer: undefined,
    filters: [],
    itemsSelector: ".js-filtered-items",
    itemsContainer: undefined,
    buttonSelector: ".js-catalog-loader",
    loaderButton: undefined,
    url: undefined,
    criteria: {
        category: [],
        collection: [],
        style: [],
        discount: [],
        stock: [],
        color: []
    },
    ready: false,
    lastUrl: "",
    init: function () {
        this.filterContainer = document.querySelector(this.filterSelector);
        if (this.filterContainer) {
            this.filterContainer.querySelectorAll("li input").forEach(this.addFilter);
            this.url = this.filterContainer.dataset.url
        }
        this.itemsContainer = document.querySelector(this.itemsSelector);
        this.loaderButton = document.querySelector(this.buttonSelector);
        if (this.loaderButton) {
            this.loaderButton.addEventListener("click", this.handleLoaderClick)
            if (this.loaderButton.getAttribute("href") === "") {
                this.loaderButton.classList.add("hidden");
            }
        }
        this.setInitialState();
        this.initialized = true;
    },
    addFilter: function (element) {
        CatalogFilter.filters.push(element);
        element.addEventListener("change", CatalogFilter.handleInputChange);
    },
    handleInputChange: function (event) {
        const element = event.target;
        const group = element.closest("[data-filter]");
        if (group) {
            const filterName = group.dataset.filter;
            CatalogFilter.setCriteria(filterName, element.value, element.checked, element.type === "radio");
            if (element.type === "checkbox") {
                CatalogFilter.normalizeCheckboxGroup(group, element);
            }
        } else {
            console.log("Cannot find element group");
        }
    },
    normalizeCheckboxGroup: function (group, element) {
        const first = group.querySelector("li:first-of-type input");
        if (element.checked) {
            if (element.value === "") {
                const selector = "li:not(:first-of-type) input";
                group.querySelectorAll(selector).forEach(this.resetCheckbox);
            } else {
                this.resetCheckbox(first);
            }
        } else {
            if (group.querySelectorAll("li input:checked").length < 1) {
                this.setCheckboxState(first, true);
            }
        }
        this.limitFilters();
        this.apply();
    },
    limitFilters: function () {
        this.limitFilter("collection");
        this.limitFilter("style");
        this.limitFilter("category");
        this.limitFilter('color');
        this.limitFilter('stock');
        this.limitFilter('discount');
    },
    limitFilter: function (key) {
        const collection = [];
        const attribute = "data-" + key;
        CatalogFilter.filterContainer.querySelectorAll(`[${attribute}]:checked`).forEach(function (checkbox) {
            checkbox.getAttribute(attribute).split(",").forEach(function (id) {
                if (!collection.includes(id)) {
                    collection.push(id);
                }
            });
        });
        const block = CatalogFilter.filterContainer.querySelector(`[data-filter="${key}"]`);
        const first = block.querySelector("li:first-of-type input");
        block.querySelectorAll('li').forEach(function (li) {
            const id = li.getAttribute("data-id");

            if (id) {
                li.classList.remove("inactive");
                if (collection.length > 0 && !collection.includes(id)) {
                    li.classList.add("inactive");
                    if (first.type === "checkbox") {
                        const checkbox = li.querySelector(":checked");
                        CatalogFilter.resetCheckbox(checkbox);
                    } else {
                        first.checked = true;
                    }
                    CatalogFilter.excludeCriterion(key, id)
                }
            }
        });
        if (!first.checked && block.querySelectorAll(":checked").length < 1) {
            CatalogFilter.checkCheckbox(first);
        }
    },
    setCheckboxState: function (checkbox, checked) {
        if (checkbox) {
            checkbox.removeEventListener("change", CatalogFilter.handleInputChange);
            checkbox.checked = checked;
            checkbox.addEventListener("change", CatalogFilter.handleInputChange);
        }
    },
    resetCheckbox: function (checkbox) {
        CatalogFilter.setCheckboxState(checkbox, false);
    },
    checkCheckbox: function (checkbox) {
        CatalogFilter.setCheckboxState(checkbox, true);
    },
    setCriteria: function (key, value, add, force) {
        if (this.criteria.hasOwnProperty(key)) {
            if (add) {
                if (value === "") {
                    this.criteria[key] = [];
                } else if (force) {
                    this.criteria[key] = [value];
                } else if (!this.criteria[key].includes(value)) {
                    this.criteria[key].push(value);
                }
            } else {
                this.criteria[key] = this.criteria[key].filter(e => e !== value)
            }
        }
        this.apply();
    },
    excludeCriterion: function (key, value) {
        if (CatalogFilter.criteria.hasOwnProperty(key)) {
            CatalogFilter.criteria[key] = CatalogFilter.criteria[key].filter(v => v !== value);
        }
    },
    setInitialState: function () {
        if (!this.filterContainer) {
            return;
        }
        this.filterContainer.dataset.category.split(",").forEach(this.checkCategory);
        this.filterContainer.dataset.collection.split(",").forEach(this.checkCollection);
        this.ready = true;
    },
    checkCategory: function (id) {
        CatalogFilter.filterContainer.querySelectorAll('[data-filter="category"] li').forEach(function (li) {
            if (li.hasAttribute("data-id")) {
                if (li.dataset.id === id) {
                    li.querySelector("input").click();
                }
            }
        });
    },
    checkCollection: function (id) {
        CatalogFilter.filterContainer.querySelectorAll('[data-filter="collection"] li').forEach(function (li) {
            if (li.hasAttribute("data-id")) {
                if (li.dataset.id === id) {
                    li.querySelector("input").click();
                }
            }
        });
    },
    apply: function () {
        const parts = []
        Object.keys(CatalogFilter.criteria).forEach((k) => {
            const f = CatalogFilter.criteria[k];
            if (f.length > 0) {
                parts.push(encodeURIComponent(`filter[${k}]`) + `=${f.join(",")}`)
            }
        })
        if (CatalogFilter.ready) {
            CatalogFilter.itemsContainer.innerHTML = "";
            CatalogFilter.loadPage(`${CatalogFilter.url}?${parts.join("&")}`);
        }
    },
    processResponse: function () {
        const chunks = []
        const response = JSON.parse(this.responseText);
        if (response.hasOwnProperty("data")) {
            response.data.forEach((item) => {
                chunks.push(item.meta.html);
            })
        }
        if (response.links.hasOwnProperty("next")) {
            CatalogFilter.loaderButton.classList.remove("hidden");
            CatalogFilter.loaderButton.href = response.links.next;
        } else {
            CatalogFilter.loaderButton.classList.add("hidden");
        }
        CatalogFilter.itemsContainer.innerHTML += chunks.join('');
    },
    loadPage: function (url) {
        if (CatalogFilter.lastUrl !== url) {
            Biovision.jsonAjaxRequest("get", url, CatalogFilter.processResponse).send();
        }
        CatalogFilter.lastUrl = url;
    },
    handleLoaderClick: function (event) {
        event.preventDefault();
        const button = event.target;
        if (button.getAttribute("href") !== "") {
            Biovision.jsonAjaxRequest("get", button.href, CatalogFilter.processResponse).send();
        }
    }
}

export default CatalogFilter;

const PaymentButton = {
    id: 'paymentButton',
    selector: ".js-order-pay",
    buttons: [],
    init: function () {
        document.querySelectorAll(this.selector).forEach(this.apply);
    },
    apply: function (element) {
        PaymentButton.buttons.push(element);
        element.addEventListener("click", PaymentButton.handle);
    },
    handle: function (event) {
        const element = event.target;
        const url = element.dataset.url;
        const request = Biovision.jsonAjaxRequest("put", url, function () {
            const response = JSON.parse(this.responseText);
            if (response.hasOwnProperty("links")) {
                document.location.href = response.links.next;
            }
        });

        element.disabled = true;
        element.innerHTML = 'Обработка…'
        request.send();
    }
}

export default PaymentButton;

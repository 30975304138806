const Loupe = {
    id: "loupe",
    initialized: false,
    selector: ".shop__item .carousel-item img",
    elements: [],
    element: undefined,
    init: function () {
        document.querySelectorAll(this.selector).forEach(this.apply);
    },
    apply: function(element) {
        Loupe.elements.push(element);
        element.addEventListener('mousemove', Loupe.mouseMove, false);
        element.addEventListener('touchmove', Loupe.touchMove, false);
    },
    mouseMove: function (e) {
        const image_box = this.getBoundingClientRect();
        const x = e.clientX - image_box.left;
        const y = e.clientY - image_box.top;
        Loupe.position(x, y);
    },
    touchMove: function (e) {
        // Строка ниже отвечает за проматывание страницы во время использования
        // лупы
        //e.preventDefault();
        const image_box = this.getBoundingClientRect();
        const touch = e.touches[0];
        const x = touch.clientX - image_box.left;
        const y = touch.clientY - image_box.top;
        Loupe.position(x, y);
    },
    position: function (x, y) {
        Loupe.elements.forEach(function (image) {
            const zoom = image.closest("li").querySelector('.zoom');
            const style = zoom.style;
            let percent_x = ((x / image.width) * 100);
            let percent_y = ((y / image.height) * 100);

            if (x > (.01 * image.width)) {
                percent_x += (.15 * percent_x);
            }
            if (y >= (.01 * image.height)) {
                percent_y += (.15 * percent_y);
            }

            style.backgroundPositionX = (percent_x - 9) + '%';
            style.backgroundPositionY = (percent_y - 9) + '%';

            style.left = (x - Math.floor(zoom.offsetWidth / 2)) + 'px';
            style.top = (y - Math.floor(zoom.offsetHeight / 2)) + 'px';
        });
    }
};

export default Loupe;
